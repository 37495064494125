import React from "react";
import { blogSneakPeak } from "staticContent/index";
import BlogSneakPeakPosts from "./BlogSneakPeakPosts";
import { CompasBreakLine } from "utils";
import "styles/components/blogSneakPeak.scss";

const BlogSneakPeak = ({ news }) => (
  <section className="section">
    <h2 className="section__title">{blogSneakPeak.sectionTitle}</h2>
    <BlogSneakPeakPosts type="news__first" color="#85BAE4" content={news[0]} />
    <CompasBreakLine />
    <BlogSneakPeakPosts type="news__second" color="#FFBA49" content={news[1]} />
  </section>
);

export default BlogSneakPeak;
