import React from "react";
import { Arrow } from "utils";

const BlogSneakPeakArrowBox = ({ settings, color }) => (
  <span className="promoted-post__arrow-box">
    <Arrow {...settings} color={color} />
    <Arrow {...settings} color={color} />
    <Arrow {...settings} color={color} />
    <span style={{ color }}>więcej</span>
  </span>
);

export default BlogSneakPeakArrowBox;
