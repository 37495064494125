import React from "react";
import Carousel from "react-multi-carousel";
import { reference } from "staticContent/index";
import { carouselSettings } from "constants/reference.constants";
import ReferenceCustomer from "./ReferenceCustomer";
import "styles/components/reference.scss";
import "react-multi-carousel/lib/styles.css";

const customersReference = reference.customers.map(({ quote, name, logo }) => (
  <ReferenceCustomer quote={quote} name={name} logo={logo} key={quote} />
));

const Reference = () => (
  <section className="section">
    <h2 className="section__title">{reference.sectionTitle}</h2>
    <Carousel {...carouselSettings}>{customersReference}</Carousel>
  </section>
);

export default Reference;
