import React from "react";
import { graphql } from "gatsby";
import Hero from "components/Hero";
import AboutUs from "components/AboutUs";
import LeafletMap from "components/Leafletmap";
import BlogSneakPeak from "components/BlogSneakPeak";
import Trust from "components/Trust";
import Reference from "components/Reference";
import Layout from "utils/Layout";
import "styles/main.scss";

const IndexPage = ({ data }) => (
  <Layout>
    <Hero path="/" />
    <AboutUs />
    <LeafletMap />
    <BlogSneakPeak news={data.allWpPost.nodes} />
    <Trust />
    <Reference />
  </Layout>
)

export default IndexPage;

export const query = graphql`
  query BLOG_INDEX_QUERY {
    allWpPost(
      sort: {fields: [date], order: DESC}
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Nowości"}}}}, status: {eq: "publish"}}
      limit: 2
    ) {
      nodes {
        title
        date
        excerpt
        slug
      }
    }
  }
`;