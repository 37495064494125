const arrowSettings = {
  size: 16,
  strokeWidth: 15,
  rotateDeg: -90,
};

const dateOptions = {
  month: "long",
  day: "numeric",
};

export { arrowSettings, dateOptions };
