import { productsLogo, cities } from "routes/picPath";
import { productsImplementation } from "routes/externalPaths";

const aboutUs = {
  section: {
    title: "o nas",
    gridItems: 8,
  },
  content: [
    {
      title: "nasz zespół",
      text: `Programiści, kierownicy projektów, wdrożeniowcy,
                testerzy – każdy pojedynczy członek naszego zespołu nie szczędzi wysiłków,
                aby w możliwie najkrótszym czasie spełnić wymagania klientów produktem o najwyższej jakości.`,
      gridIndex: 1,
    },
    {
      title: "",
      text: "",
      gridIndex: 2,
    },
    {
      title: "potrzeby",
      text: `Nasze oprogramowanie tworzone jest z myślą o potrzebach naszych klientów.
                Stały kontakt pozwala nam na bieżąco tworzyć nowe rozwiązania i dostosowywać istniejące.
                Dzięki temu praca z naszymi produktami staje się coraz bardziej efektywna.`,
      gridIndex: 3,
    },
    {
      title: "doświadczenie",
      text: `Na swoim koncie mamy już kilkadziesiąt zakończonych sukcesem projektów usprawniających działanie jednostek samorządów terytorialnych.`,
      gridIndex: 4,
    },
    {
      title: "kreatywność",
      text: `Pracownicy firmy dążą do poszukiwania i realizacji nowych, kreatywnych pomysłów, korzystając zarówno z doświadczenia, jak i wyobraźni.`,
      gridIndex: 5,
    },
    {
      title: "",
      text: "",
      gridIndex: 6,
    },
    {
      title: "najlepszy stosunek ceny do jakości",
      text: `W porównaniu z innymi produktami na rynku wszystkie nasze projekty cechują się najwyższą jakością w przystępnej cenie.`,
      gridIndex: 7,
    },
    {
      title: "oprogramowanie",
      text: `Ciągle dążymy do lepszej optymalizacji kodu i implementacji nowych technologii.
                Większa wydajność, szybsze działanie i pewność uzyskanego wyniku – to nasze priorytety.`,
      gridIndex: 8,
    },
  ],
};

const blogSneakPeak = {
  sectionTitle: "Najnowsze wpisy z naszego bloga"
};

const hero = {
  bannerText: "systemy informatyczne \ndo zarządzania przestrzenią",
  cardsContent: [
    {
      logo: {
        src: productsLogo.isdp,
        alt: "logo isdp",
      },
      path: "/more/products#isdp",
      pathMobile: "/more/products",
      name: {
        short: "isdp",
        extended: "Internetowy Serwer Danych Przestrzennych",
      },
      description:
        "system informatyczny i baza danych przestrzennych, umożliwiające gromadzenie, aktualizację i udostępnianie interaktywnych map i zestawień w środowisku przeglądarki WWW",
    },
    {
      logo: {
        src: productsLogo.gpt,
        alt: "logo gpt",
      },
      path: "/more/products#gpt",
      pathMobile: "/more/products",
      name: {
        short: "gpt",
        extended: "Geoportal Toolkit \n \u00A0",
      },
      description:
        "zestaw modułów umożliwiających tworzenie, konfigurację i publikację serwisów mapowych, a także łatwe i intuicyjne zarządzanie oraz administrowanie nimi",
    },
    {
      logo: {
        src: productsLogo.sowa,
        alt: "logo sowa",
        ratio: "1",
      },
      path: "/more/products#sowa",
      pathMobile: "/more/products",
      name: {
        short: "sowa",
        extended: "System Obsługi Wniosków Administracyjnych",
      },
      description:
        "system wspomagający obsługę procesu przepływu dokumentów oraz wymiany informacji wewnątrz urzędu lub dowolnej organizacji",
    },
    {
      logo: {
        src: productsLogo.szopp,
        alt: "logo szopp",
      },
      path: "/more/products#szopp",
      pathMobile: "/more/products",
      name: {
        short: "szopp",
        extended: "System Zintegrowanej Obsługi Planowania Przestrzennego",
      },
      description:
        "moduł, który pozwala na zarządzanie Miejscowymi Planami Zagospodarowania Przestrzennego oraz Studium Uwarunkowań i Kierunków Zagospodarowania Przestrzennego",
    },
  ],
};

const leafletMap = {
  sectionTitle: "Gdzie jesteśmy obecni",
  location: {
    position: [52.114503, 19.423561],
    zoom: 6,
  },
  clients: [
    {
      position: [53.438056, 14.542222],
      markerText: "Szczecinie",
      path: productsImplementation.szczecin,
    },
    {
      position: [51.212778, 17.383056],
      markerText: "Oleśnicy",
      path: productsImplementation.pOlesnicki,
    },
    {
      position: [51.363611, 19.362222],
      markerText: "Bełchatowie",
      path: productsImplementation.belchatow,
    },
    {
      position: [50.874167, 20.633333],
      markerText: "Kielcach",
      path: productsImplementation.kielce,
    },
    {
      position: [50.029167, 20.971667],
      markerText: "Tarnowie",
      path: productsImplementation.tarnow,
    },
    {
      position: [50.061389, 19.938333],
      markerText: "Krakowie",
      path: "",
    },
    {
      position: [49.647222, 18.8675],
      markerText: "Wiśle",
      path: "",
    },
    {
      position: [49.950278, 18.575278],
      markerText: "Jastrzębiu-Zdroju",
      path: productsImplementation.jastrzebie,
    },
    {
      position: [50.123611, 18.986667],
      markerText: "Tychach",
      path: productsImplementation.tychy,
    },
    {
      position: [50.204444, 19.27],
      markerText: "Jaworznie",
      path: productsImplementation.jaworzno,
    },
    {
      position: [50.292222, 18.6675],
      markerText: "Gliwicach",
      path: productsImplementation.gliwice,
    },
    {
      position: [50.348333, 18.915556],
      markerText: "Bytomiu",
      path: productsImplementation.bytom,
    },
    {
      position: [50.373611, 18.943889],
      markerText: "Piekarach Śląskich",
      path: productsImplementation.piekary,
    },
    {
      position: [50.283333, 19.133333],
      markerText: "Sosnowcu",
      path: productsImplementation.sosnowiec,
    },
    {
      position: [50.241389, 19.136667],
      markerText: "Mysłowicach",
      path: productsImplementation.myslowice,
    },
    {
      position: [49.8225, 19.0444447],
      markerText: "Bielku Białej",
      path: "",
    },
    {
      position: [50.875, 18.416667],
      markerText: "powiecie oleskim",
      path: "",
    },
    {
      position: [50.664722, 17.926944],
      markerText: "Domu Wspołpracy Polsko-Niemieckiej",
      path: productsImplementation.domWspolpracy,
    },
    {
      position: [50.264167, 19.023611],
      markerText: "Katowicach na Uniwersytecie Śląskim",
      path: productsImplementation.katowice,
    },
  ],
};

const reference = {
  sectionTitle: "Co myślą o nas klienci",
  customers: [
    {
      quote: `"Firma była odpowiedzialna za kompletnie nowe stworzenie materiału. Firma wykazała się profesjonalizmem, zaangażowaniem i kreatywnością w tworzeniu niniejszego dwujęzycznego materiału edukacyjnego."`,
      name: {
        person: "Lucjan Dzumla",
        place: "Dyrektor generalny Domu Współpracy Polsko - Niemieckiej",
      },
      logo: {
        src: cities.logo.dwpn,
        alt: "Logo dom wspólpracy polsko-niemieckiej",
      },
    },
    {
      quote: `"Projekt został zrealizowany z należytą starannością, dużym zaangażowaniem pracowników wykonawcy oraz z uwzględnieniem wszelkich wymagań Zamawiającego."`,
      name: {
        person: "Zygmunt Mazur",
        place: "Urząd Miasta Kielce",
      },
      logo: {
        src: cities.arms.kielce,
        alt: "Logo Kielce",
      },
    },
    {
      quote: `"Projekt został zrealizowany terminowo, z należytą starannością, dużym zaangażowaniem pracowników wykonawcy oraz z uwzględnieniem wszelkich wymagań SIWZ"`,
      name: {
        person: "Marzena Danecka",
        place: "Geodeta Powiatowy Miasta Piekary Śląskie",
      },
      logo: {
        src: cities.arms.piekary,
        alt: "Logo Piekar Śląskich",
      },
    },
  ],
};

const trust = {
  sectionTitle: "Zaufali nam",
  customers: [
    {
      src: cities.logo.dwpn,
      alt: "Dom Współpracy Polsko-Niemieckiej logo",
    },
    {
      src: cities.arms.belchatow,
      alt: "Bełchatów herb",
    },
    {
      src: cities.arms.bytom,
      alt: "Bytom herb",
    },
    {
      src: cities.arms.gliwice,
      alt: "Gliwice herb",
    },
    {
      src: cities.arms.jastrzebie,
      alt: "Jastrzębie Zdrój herb",
    },
    {
      src: cities.arms.jaworzno,
      alt: "Jaworzno herb",
    },
    {
      src: cities.arms.kielce,
      alt: "Kielce herb",
    },
    {
      src: cities.arms.krakow,
      alt: "Kraków herb",
    },
    {
      src: cities.arms.krosno,
      alt: "Krosno herb",
    },
    {
      src: cities.arms.myslowice,
      alt: "Mysłowice herb",
    },
    {
      src: cities.arms.piekary,
      alt: "Piekary Śląskie herb",
    },
    {
      src: cities.arms.powOlesnicki,
      alt: "powiat Oleśnicki herb",
    },
    {
      src: cities.arms.sosnowiec,
      alt: "Sosnowiec herb",
    },
    {
      src: cities.arms.szczecin,
      alt: "Szczecin herb",
    },
    {
      src: cities.arms.tarnow,
      alt: "Tarnów herb",
    },
    {
      src: cities.arms.tychy,
      alt: "Tychy herb",
    },
  ],
};

export { aboutUs, blogSneakPeak, hero, leafletMap, reference, trust };
