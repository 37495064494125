import React from "react";
import { Marker, Tooltip } from "react-leaflet";

const LeafletMapPin = ({ position, markerText, path }) => (
  <Marker
    position={position}
    eventHandlers={{
      click: () => path && window.open(path, "_blank").focus(),
    }}
  >
    <Tooltip>Wdrożenie w {markerText}</Tooltip>
  </Marker>
);

export default LeafletMapPin;
