import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Arrow } from "utils";

const HeroCard = ({ logo, path, pathMobile, name, description }) => {
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    setPageWidth(window.screen.width);
  }, []);

  return (
    <Link to={pageWidth > 600 ? path : pathMobile}>
      <div className="hero__card">
        <img
          className="hero__card--logo"
          loading="lazy"
          src={logo.src}
          alt={logo.alt}
          style={{ aspectRatio: `${logo.ratio}` }}
        />
        <div className="hero__card--title">
          <h2>{name.short}</h2>
          <p>{name.extended}</p>
        </div>
        <Arrow className="hero__card--arrow" size={50} strokeWidth={6} />
        <p className="hero__card--info">{description}</p>
      </div>
    </Link>
  );
};

export default HeroCard;
