const carouselResponsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1440,
    },
    items: 8,
    partialVisibilityGutter: 40,
  },
  laptop: {
    breakpoint: {
      max: 1440,
      min: 1024,
    },
    items: 5,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 600,
    },
    items: 4,
    partialVisibilityGutter: 30,
  },
};

const carouselSettings = {
  arrows: false,
  autoPlay: true,
  infinite: true,
  autoPlaySpeed: 1,
  itemClass: "customers-trust__item",
  sliderClass: "customers-trust__slider",
  customTransition: "all 12s linear",
  transitionDuration: 12000,
  responsive: carouselResponsive,
};

export { carouselSettings };
