import React from "react";

const Grid = ({ title, text, gridIndex }) => (
  <div className={`grid__element grid__element--${gridIndex}`}>
    {title && (
      <h3
        className={`grid__element--title${
          gridIndex === 1 || gridIndex === 5 ? "-underline" : ""
        }`}
      >
        {title}
      </h3>
    )}
    {text && <p className="grid__element--text">{text}</p>}
  </div>
);

export default Grid;
