const carouselResponsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1440,
    },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 40,
  },
  laptop: {
    breakpoint: {
      max: 1440,
      min: 1024,
    },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 300,
    },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 30,
  },
};

const carouselSettings = {
  swipeable: false,
  draggable: false,
  arrows: false,
  autoPlay: true,
  infinite: true,
  autoPlaySpeed: 1,
  slidesToSlide: 1,
  transitionDuration: 5000,
  responsive: carouselResponsive,
};

export { carouselSettings };
