import React from "react";
import { Link } from "gatsby";
import BlogSneakPeakArrowBox from "./BlogSneakPeakArrowBox";
import { arrowSettings, dateOptions } from "constants/blogSneakPeak.constants";
import { converDateToSlug, getFormatedDateSneakPeak, createExcerpt } from "helpers/blog.helpers";

const BlogSneakPeakPosts = ({ type, color, content }) => {
  const { title, date } = content;
  const showDate = (start, length) =>
    getFormatedDateSneakPeak(date, dateOptions).substr(start, length);
  const excerpt = createExcerpt(content.excerpt);

  return (
    <>
      <div className={`promoted-post promoted-post--${type}`}>
        <div className={`promoted-post__date promoted-post__date--${type}`}>
          <p>{date && showDate(0, 2)}</p>
          <p>{date && showDate(3, 3)}</p>
        </div>
        <Link to={`/blog/${converDateToSlug(date, content.slug)}`} className="promoted-post__content-wrapper">
          <h4 className="promoted-post__title">{title}</h4>
          <p className="promoted-post__text" dangerouslySetInnerHTML={{ __html: excerpt }} />
          <BlogSneakPeakArrowBox settings={arrowSettings} color={color} />
        </Link>
      </div>
    </>
  );
};

export default BlogSneakPeakPosts;
