import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import LeafletMapPin from "./LeafletMapPin";
import { leafletMap } from "staticContent/index";
import { tileSettings } from "constants/leafletmap.constants";

const {
  location: { zoom, position },
  clients,
  sectionTitle,
} = leafletMap;

const productsImplementation = clients.map(client => (
  <LeafletMapPin key={client.position[0].toString()} {...client} />
));

const LeafletMap = () => {
  const [pageWidth, setPageWidth] = useState(0);
  const [mapZoom, setMapZoom] = useState(zoom);

  useEffect(() => {
    setPageWidth(window.screen.width);
  }, []);

  useEffect(() => {
    setMapZoom(pageWidth > 600 ? zoom : 5);
  }, [pageWidth]);

  return (
    <section className="section leaflet-map">
      <h2 className="section__title section__title--leaflet-map">
        {sectionTitle}
      </h2>
      {typeof window !== "undefined" && (
        <MapContainer center={position} zoom={mapZoom} scrollWheelZoom={false}>
          <TileLayer {...tileSettings} />
          {productsImplementation}
        </MapContainer>
      )}
    </section>
  );
};

export default LeafletMap;
