import React from "react";

const ReferenceCustomer = ({ quote, name, logo }) => (
  <div className="customer-reference">
    <p className="customer-reference__quote">{quote}</p>
    <div className="customer-reference__name">
      <p className="customer-reference__name--person">{name.person}</p>
      <p className="customer-reference__name--place">{name.place}</p>
    </div>
    <img
      className="customer-reference__logo"
      src={logo.src}
      loading="lazy"
      alt={logo.alt}
    />
  </div>
);

export default ReferenceCustomer;
