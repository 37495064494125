import React from "react";
import Carousel from "react-multi-carousel";
import { trust } from "staticContent/index";
import { carouselSettings } from "constants/trust.constants";
import "react-multi-carousel/lib/styles.css";

const customers = trust.customers.map(({ alt, src }) => (
  <img loading="lazy" key={alt} src={src} alt={alt} />
));

const Trust = () => (
  <section className="section customers-trust">
    <h2 className="section__title section__title--leaflet-map">
      {trust.sectionTitle}
    </h2>
    <Carousel {...carouselSettings}>{customers}</Carousel>
  </section>
);

export default Trust;
