import React from "react";
import HeroCard from "./HeroCard";
import { hero } from "staticContent/index";
import "styles/components/hero.scss";

const heroCards = hero.cardsContent.map(card => (
  <HeroCard key={card.name.short} {...card} />
));

const Hero = () => (
  <section className="section hero main">
    <h1 className="hero__banner-text">{hero.bannerText}</h1>
    <div className="hero__cards-wrapper">
      <div className="hero__cards-bcg"></div>
      {heroCards}
    </div>
  </section>
);

export default Hero;
