import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const staticImgs = {
  img1: (
    <StaticImage
      className="grid__img--1"
      src="../../assets/images/mapa.jpg"
      alt="mapa"
      placeholder="blurred"
    />
  ),
  img2: (
    <StaticImage
      className="grid__img--2"
      src="../../assets/images/lightbulb.jpg"
      alt="żarówka"
      placeholder="blurred"
    />
  ),
};

export { staticImgs };
