import React from "react";
import Grid from "./Grid";
import { aboutUs } from "staticContent/index";
import { staticImgs } from "constants/aboutUs.constants";
import "styles/components/aboutUs.scss";

const gridElements = aboutUs.content.map((item, index) => (
  <Grid key={index} {...item} />
));

const AboutUs = ({ scrollTarget }) => (
  <section ref={scrollTarget} className="section about-us">
    <h2 className="section__title--simple about-us__title">
      {aboutUs.section.title}
    </h2>
    <div className="about-us__grid">
      {staticImgs.img1}
      {staticImgs.img2}
      {gridElements}
    </div>
  </section>
);

export default AboutUs;
